import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import HeaderBannerSimpleCir from '@babylon/ui-kit-wrappers/components/circuitos/header-banner-searcher';
import type { HeaderBannerSimpleCirProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/header-banner-searcher/types';
import type { ComponentProps, FC } from 'react';

const HeaderBannerHydrated =
	withHydrationOnDemand<ComponentProps<typeof HeaderBannerSimpleCir>>()(HeaderBannerSimpleCir);

export const renderBannerSearcher: FC<HeaderBannerSimpleCirProps> = (mBannerSearcher: HeaderBannerSimpleCirProps) => (
	<HeaderBannerHydrated {...mBannerSearcher} />
);
