import _merge from 'lodash/merge';

export const dataConverter = (props: any) => {
	const {
		searcher,
		config,
		masonry,
		features,
		textCutCollapseBox,
		tabsProductCir,
		tabsCarousel,
		carousel,
		headerBanner,
		carouselCardSimple,
	} = props;

	if (searcher) {
		props.searcherCir = _merge(searcher, {
			props: {
				...searcher,
				config: {
					...config,
				},
			},
		});
	}

	if (carouselCardSimple) {
		props.carouselCardSimple = _merge(carouselCardSimple, {
			props: {
				...carouselCardSimple,
				config: {
					...config,
				},
			},
		});
	}

	if (masonry) {
		props.masonry = _merge(masonry, {
			masonry: {
				...masonry,
			},
		});
	}

	if (features) {
		props.features = _merge(features, {
			props: {
				...features,
				title: {
					content: features.props?.heading?.title ? features.props?.heading?.title : '',
				},
				features: {
					items: features?.props?.features,
				},
			},
		});
	}
	if (textCutCollapseBox) {
		props.textCutCollapseBox = _merge(textCutCollapseBox, {
			props: {
				...textCutCollapseBox,
				config: {
					...config,
				},
			},
		});
	}

	if (tabsCarousel) {
		props.tabsCarousel = {
			...tabsCarousel,
			props: {
				...tabsCarousel.props,
				config: {
					...tabsCarousel.props?.config,
					carousel: false,
				},
				title: tabsCarousel?.props?.title ?? carousel.props?.title,
				subtitle: tabsCarousel?.props?.subtitle ?? carousel.props?.subtitle,
			},
		};
	}

	if (tabsProductCir) {
		props.tabsProductCir = {
			...tabsProductCir,
			props: {
				...tabsProductCir.props,
				config: {
					...tabsProductCir.props?.config,
					carousel: false,
				},
			},
		};
	}

	if (headerBanner) {
		props.headerBanner = {
			...headerBanner,
			props: {
				...headerBanner.props,
				titleTag: 'h2',
			},
		};
	}

	if (textCutCollapseBox && props?.headerBanner?.props?.items[0]?.link?.href) {
		props.textCutCollapseBox = _merge(textCutCollapseBox, {
			...props?.textCutCollapseBox,
			id: props?.headerBanner?.props?.items[0]?.link?.href.replace('#', ''),
		});
	}

	return { data: props };
};
