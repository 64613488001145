import { TabsProductWrapperMobile } from '@babylon/ui-kit-common/components/others/tabs-carousel-product-card/views/mobile/styled';
import { TabsButton } from '@babylon/ui-kit-structures/components/others/tabs/styled';
import { Border, Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { css } from 'styled-components';

import type { CircuitsMobileStyles } from '../types';

const liverpoolCircuitsMobileStyles: CircuitsMobileStyles = {
	StyledSectionTabsProductCir: () => css`
		${Padding({ x: '16px' })}
		background-color: ${({ theme }) => theme.bodyBgColor};
		${Margin({ top: '36px' })}

		${TabsProductWrapperMobile} {
			${Border({ bottom: 'unset' })}

			${TabsButton} {
				${({ theme }) => Border({ bottom: `2px solid ${theme.colors.grays.lighter}` })};

				&.is-active {
					border-bottom: 2px solid var(--active-border-bottom-color);
				}
			}
		}
	`,
};

export default liverpoolCircuitsMobileStyles;
