import { type FC } from 'react';

import * as S from './styled';
import type { TravelCardProps } from './types';

const TravelCard: FC<TravelCardProps> = props => {
	const { title, description, image, link } = props;
	const processedImage = typeof image === 'string' ? image : image?.src;

	return (
		<S.TravelCardLink {...link} data-testid='TravelCard'>
			<S.TravelCardWrapper>
				{processedImage && <S.TravelCardImage resize={{ width: 500, height: 500 }} src={processedImage} />}

				<S.TravelCardInfo>
					<S.TravelCardTextWrapper>
						{title && <S.TravelCardTitle>{title}</S.TravelCardTitle>}

						{description && <S.TravelCardDescription>{description}</S.TravelCardDescription>}
					</S.TravelCardTextWrapper>

					{link?.content && (
						<S.TravelCardButton data-testid='viewAllButton' height='auto' variant='negative'>
							{link.content}
						</S.TravelCardButton>
					)}
				</S.TravelCardInfo>
			</S.TravelCardWrapper>
		</S.TravelCardLink>
	);
};

export default TravelCard;
